a{
  text-decoration: none;
  color: black;
}

.hc-6-1{
    /* background: black; */
    color: white;
    /* margin: 50px 0px; */
    padding: 50px 0px;
}

.hc-6-h1{

 
    text-transform: uppercase;
    font-size: 40px;
    text-align: center;
  }
  .bmr-bmi-form-d1{
  /* width: 100%; */
  /* padding: 50px 0px; */
  /* display: flex;
  justify-content: center; */
  }
  
  .bmr-d1{
    /* width: 80%; */
  /* width: 100%;  */

    display: flex;
    padding: 0px 0px 50px 0px;
    justify-content: space-between;
    align-items: center;
  }
  
  .form-rh{
  width: 500px;
  }
  
  
  .bmr-head-h-p>h1{
    font-size: 55px;
    font-weight: 700;
    color: #0f172a;
    margin-bottom: 15px;
  }
  .bmr-head-h-p>p{
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0px;
    word-wrap: break-word;
    font-weight: 400;
    background-color: #fff;
    color: #6e6e6e;
}
  
  
  
  .div-input-bmr{
  display: flex;
  justify-content: space-between;
  }
  
  .input-bmr-div{
  width: 48%;
  display: flex;
  }
  .input-bmr-div>input{
    width: 100%;
    padding: 10px;
    white-space: nowrap;
  }
  
  .input-bmr-div>select{
    width: 100%;
    padding: 10px;
    white-space: nowrap;
  }
  
  
  .form-bmr{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  
  .form-lh {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  
  .btn-cal-1{
    background: white;
    color: black;
    padding: 14px 50px;
    border-radius: 5px;
    border: 2px solid gray;

  }
  
  
  .btn-cal-1:hover{
  
    background: #f52225;
    transition: all 0.3s ease;
    color: white;
    border: 2px solid white;
  
  }


  @media (max-width:750px) {
    .bmr-d1 {
      /* width: 80%; */
      /* width: 100%; */
      display: flex;
      flex-direction: column;
  }
  .form-lh {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-rh {
  width: 100%;

}


.table-container {

  margin: 20px 0px 0px 0px  !important;
}

  }
  
  
  
  .bmr-result1{
    display: flex;
    justify-content: space-between;
    align-items: center;
     flex-wrap: wrap;
  }
  
  .bmr-result2{
    
  }
  .bmr-result3>h3{
    font-size: 16px;
  }
  
  /* App.css */
  
  .table-container {
    margin: 20px;
  }
  
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 16px;
  }
  
  .styled-table th,
  .styled-table td {
    /* border-bottom: 1px solid #ddd; */
    padding: 8px;
    text-align: left;
    padding: 17px;
  }
  
  .styled-table th {
  
    font-weight: bold;
  }
  
  .styled-table td:nth-child(1),
  .styled-table th:nth-child(1),
  .styled-table td:nth-child(1),
  .styled-table th:nth-child(1) {
    border-right: 1px solid #ddd; /* Add border between Name and Age columns */
  }
  
  
  
  .styled-table th,
  .styled-table tr {
    border-bottom: 1px solid #ddd;
  
  }
  .table-rh-h1{
    color: rgba(128, 128, 128, 0.709);
    font-size: 15px;
    text-align: center;
  }
  
  .table-rh-h1>span{
  color: black;
  }
  
  