.check-lh{

    width: 650px;
    
}



    .check-1{
    width: 100%;
    display: flex;
    justify-content: center;
    }
    .check-2{
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 40px;
        width: 80%;
        justify-content: space-between;
        display: flex;
        margin: 50px 0px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 5px;
    }
    .check-rh{
    width: 500px;

    
    }
    
    .check-lh-h1{
         font-size: 25px;
         margin-bottom: 30px;
    }
    .check-lh2{
        /* padding: 50px 0px; */
    }
    
    .input-lh-d1{
        display: flex;
        width: 100%;
    }
    
    .input-check-div2{
        width: 100%;
        display: flex;
    }
    
    
    .input-check-div2>input {
        width: 100%;
        padding: 10px !important;
        white-space: nowrap;
        border-radius: 5px;

    }
    
    
    /* .input-check-div2>input:focus {
        border:    2px solid  black;
        outline: none !important;
      }
    
    .input-check-div>input:focus {
        border:    2px solid  black;
        outline: none !important;
      }
     
     */
    
    
    
      .input-check-div{
        width: 48%;
        display: flex;
    }
    .div-check-in{
        display: flex;
        justify-content: space-between;
    }
    
    .input-check-div>input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;

        white-space: nowrap;
    }
    .input-check-div>select{
        width: 100%;
        padding: 10px;
        white-space: nowrap;
        
        border: 1px solid rgba(128, 128, 128, 0.185);
    }
    
    
    
    .enroll-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        /* padding: 50px 0px; */
    }
    


    .check-lh-d4>h1{
font-size: 12px;
    }


    .check-lh-d4{
        border: 1px solid rgba(128, 128, 128, 0.39);
       padding: 10px;
       border-radius: 5px;

       display: flex;
       flex-direction: column;
       gap: 7px;
            }

    .check-lh-d4>span{
        font-size: 12px;

    
            }



            .check-ul-lh{
                padding: 0px 25px;
                list-style: number;
            }


            .check-ul-lh>li{
                font-size: 12px;
            }


.pay-now-check{

    
    cursor: none;
    padding: 15px;
    background: #f52225;
    color: white;
    border: none;
    border-radius: 5px;

}

.check-rh-h1{
    font-size: 15px;
    /* margin-bottom: 30px; */
}

.check-rh-d1{
    border: 1px solid rgba(128, 128, 128, 0.41);
    border-radius: 5px;



}

.check-rh{
    
}

.chec-rh-f1{
    display: flex;
    flex-direction: column;
    gap: 10px;
}



.check-rh-d2{
    display: flex;
    justify-content: space-between;
    padding: 20px;
}


.check-rh-d2>h1{

 font-size: 14px;
}

.package-choose{
   flex-wrap: wrap;
   gap: 10px;
    align-items: center;
 display: flex;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid rgba(128, 128, 128, 0.41);
  border-bottom: 1px solid rgba(128, 128, 128, 0.41);



}
.package-choose-d2>h1{
    font-size: 13px;


    
}

.package-choose-d2{
  display: flex;
  align-items: center;
  gap: 5px;
    
}


.package-choose-d2>img{
    width: 60px;
     border-radius: 5px;
    
}
.package-choose>h1{
    font-size: 15px;
    
}

.check-rh-d3{
    align-items: center;
    display: flex;
     justify-content: space-between;
     padding: 20px;
}


.check-rh-d3>h1{
    font-size: 15px;

}

.check-rh-d4{
    align-items: center;
    display: flex;
     justify-content: space-between;
     padding: 20px;
  border-top: 1px solid rgba(128, 128, 128, 0.41);

}


.check-rh-d4>h1{
    font-size: 15px;

}

@media (max-width:500px) {
    .check-2 {
    
        width: 95% !important;
    }

}

@media (max-width:900px) {

    .check-2 {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 20px;
        width: 90%;
  
        display: flex;
  gap: 40px;
  flex-direction: column;
    }


    .check-rh {
        width: 100%;
    }
    .check-lh {
        width: 100%;

    }

}


.coupon-section{
display: flex;
gap: 10px;
}
.coupon-input-div{
    width: 100%;

}
.coupon-input-div>input{
    width: 100%;
    padding: 10px;
    white-space: nowrap;
}

.coupon-section>button{

   
    background: #f52225;
    color: white;
    border: none;
    padding: 10px 40px;
    border-radius: 5px;

}



select { 
    /* -webkit-appearance: none; */
    -moz-appearance: none;
    /* appearance: none; */
    color: black;
    background-color: white;
    border-radius: 5px !important;
    border: 1px solid rgba(128, 128, 128, 0.425);

    
}

input:focus {
    border: 1px solid black;
    outline: 2px solid black;
}

select:focus {
    border: 1px solid black;
    outline: 1px solid black;
}

input{
    border-radius: 5px !important;

}