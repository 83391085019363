
.hc4-1{
width: 100%;
display: flex;
justify-content: center;
}

.hc4-2{

  width: 100%;
  display: flex;
  justify-content:  space-between;
  align-items: center;
  padding: 50px 0px;

}
.hc4-lh1{
/* width: 50%; */
width: 500px;

}
.hc4-rh1{
 
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* width: 500px; */
}

.hc4-d2>h1{
    font-size: 40px;
    font-weight: 700;
    color: #0f172a;
     text-transform: uppercase;
}
.hc4-d2>p{

  text-transform: uppercase;
  font-size: 15px;

}

.hc4-d2>img{
width: 500px;
border-radius: 5px;
}

.hc4-d2{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.insta-post-1{

  width: 100%;
  height: 100%;
}

.img-grid-hc41>img{
  width: 300px;
  height: 300px;
  border-radius: 5px;
}

.img-grid-hc42{
 display: flex;
 gap: 10px;
 flex-direction: column;
}

.img-grid-hc42>img{
  width: 150px;
  height: 150px;
  border-radius: 5px;
}

.hc5img-grid-1{
  width: 150px;
  height: 150px;
  border-radius: 5px;
  object-fit: cover;
}

.img-grid-hc43{

}
.hc5img-grid-2{
  width: 300px;
  height: 310px;
  border-radius: 5px;
  object-fit: cover;
}



.hc4-rh2{
  display: flex;
  gap: 10px;

}
.hc4-rh3{
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.insta-dp{

display: flex;
}
.img-dp-insta>img{
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: white;
  padding: 3px;
}
.img-dp-insta{
  background: linear-gradient(45deg,#fa9b57 0,#f7763f 35%,#e34876 55%,#dc327c 75%,#c73390 100%);
  display: flex;
  border-radius: 50px;
  padding: 3px;
  cursor: pointer;
}

.insta-head-name {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.user-name-insta{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  cursor: pointer;
}

.insta-user-name>p{
  text-transform: uppercase;
  font-size: 12px;

}

.span-1-hc4{
  color: gray;
  font-weight: bold;
}

.a-tag-df{
  display: flex;
}
.img-grid-hc4{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hc5-1{
  padding: 50px;
  background-color: black;
  color: white;
}

.hc5-2{

}
.hc5-3{

}

.hc5-h1{
 text-align: center;
 text-transform: uppercase;
 font-size: 30px;
}
.hc5-h2{

  
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 40px;
 text-align: center;

}




.card-price-1{
  width: 350px;
  border: 1px solid  rgba(211, 211, 211, 0.317);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  
}

.card-price-img{
  width: 350px;
  height: 350px;
  object-fit: cover;
}

.plan-card-head{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 25px;
   text-align: center;
}
.card-price-d3{
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.cards-points{



  text-align: center;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;


}

.cards-points>h1{
  font-size: 16px;
  align-items: center;
  text-transform: uppercase;
}

.jn-btn-price{
  background-color: transparent;
  border: 1px solid lightgrey;
  color: white;
  padding: 10px 30px;
  border-radius: 5px;
}



@media (max-width:1080px){

  .hc4-2 {
  
    display: flex;
    flex-direction: column;
}
.hc4-d2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.hc4-lh1 {
  /* width: 50%; */
  width: 100%;
}

.nav-ul1>li {
  cursor: pointer;
  font-size: 12px;
}
.hc4-rh1{
 
  width: 100%;

}
.logo-nav-h1 {
  font-size: 15px !important;
}

.hc5img-grid-2 {
  width: 100%;

  height: 310px;
  border-radius: 5px;
  object-fit: cover;
}

.img-grid-hc42 {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.a-tag-df {
  width: 100%;

}

.hc5img-grid-1 {
  width: 100%;

  height: 150px;
  border-radius: 5px;
  object-fit: cover;
}

.hc4-rh2 {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.hc4-rh1 {
  display: flex;
  flex-direction: column;
  gap: 50px;

  padding: 20px 0px;
}
.bmr-d1 {
  width: 95%;
  display: flex;
  flex-direction: column;
}
.form-lh {
  width: 100% !important;
 
}
.form-rh {
  width: 100% !important;
}

.home-d1 {
  
  height: 80vh;
  background-position: center;
  background-size: cover;
  padding:  unset;
  align-items: center;
  display: flex;
  justify-content: center;
}

}


@media (max-width:600px){
  .hc-7-2 {
    background-color: black;
    color: white;
    padding: 50px 10px;
}

.hc2-d4 {
  display: flex;

  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}
.hc4-d2>img {
  width: 100%;
  border-radius: 5px;
}

.hc4-rh2 {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.img-grid-hc42 {
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.hc5-1 {
  padding: 20px;
  background-color: black;
  color: white;
}
.card-price-1 {
  width: 100%;

}
.card-price-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.hc-8-4>h1 {

  font-size: 19px;

}
.hc-8-4>p{

  font-size: 19px;

}
.hc-8-lh>h1 {
  
  font-size: 30px;

}
.hc-8-3 {
  width: 95%;

}

.home-c-h1 {
font-size: 24px;

}
.home-d-3>h1 {
  font-size: 15px;
  text-transform: uppercase;
}
.hc-6-h1 {

  font-size: 20px;

}
.bmr-head-h-p>h1 {

  font-size: 34px;
}

.hc4-d2>p {
  text-transform: uppercase;
  font-size: 15px;

  text-align: center;
}
.lh-hc3 {

  width: 100%;
}
.rh-hc3>img {
  width: 100%;
  border-radius: 5px;
}
}
@media (max-width:500px){

  .test-img {
    width: 121px;
    height: 114px;
    object-fit: cover;
}
.div-nav2{
  display: flex;
  justify-content: space-between;
  padding: 20px 20px !important;
color: white;
  align-items: center;

}
}

@media (max-width:340px){
  .nav-ul1>li {
    cursor: pointer;
    font-size: 10px !important;
}

}

@media (max-width:500px){

.test-img {
  width: 82px;
  height: 78px;
  object-fit: cover;
}
}


.insta-username{
    display: flex ;
    gap: 5px;
}