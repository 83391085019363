.logo-head2>img{
 width:80px;

}

.head1{
    padding: 20px;
 border-bottom: 1px solid gray;
 display: flex ;
  justify-content:  space-between;
  align-items: center;
}

.nav-2-a1{
    color:black;
    transition: all 0.2s ease-in-out;
}

.nav-2-a1:hover{
    color:#f52225;

}